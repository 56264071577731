<template>
  <div class="w-full relative">
    <TotalComplaints :total="count" title="Total de Quejas" />
        
    <ComplaintsFilter
      @fetch-complaints="setQueries"
      :query="start_query"
      :loading="loading"
    />
    <div class="py-12" v-if="legend">
      <b class="text-center text-4xl mx-auto text-gray-400">{{ legend }}</b>
    </div>
    <div
      v-if="complaints.length > 0"
      class="bg-white overflow-x-auto rounded-xl border border-gray-300 mx-4 mt-4 search-complaints"
    >
      <div class="p-4 flex justify-start">
        <a-menu mode="horizontal">
          <a-sub-menu>
            <a-button
                slot="title"
                type="primary"
                style="margin-bottom: 16px"
                v-on:click="collapsed = !collapsed"
            >
              <a-icon :type="collapsed ? 'menu-unfold' : 'menu-fold'" />
            </a-button>
            <a-menu-item v-if="collapsed">
              <vue-excel-xlsx
                  :loading="loadingReport"
                  :dataSheets="[complaintsInfo]"
                  :sheetNames="['Reporte']"
                  book
                  :transpose="[false, true]"
                  :columnsSheets="[columnsExcel]"
                  file-name="Búsqueda de quejas"
              >
                <span class="mx-2">Descargar vista actual XLSX</span>
              </vue-excel-xlsx>
            </a-menu-item>
            <a-menu-item v-if="collapsed">
                <span class="mx-2" v-on:click="onGetAllComplaints('xlsx')">Descargar reporte completo XLSX</span>
            </a-menu-item>
            <a-menu-item v-if="collapsed">
              <span class="mx-2" v-on:click="onGetAllComplaints('csv')">Descargar reporte completo CSV</span>
            </a-menu-item>
            <!--
            <a-menu-item v-if="collapsed && loading">
              <div v-if="loading">
                <progress :value="progress" max="100"></progress>
                <p>{{ progress }}%</p>
              </div>
            </a-menu-item>
            -->
          </a-sub-menu>
        </a-menu>
      </div>
      <a-table
        :columns="columns"
        rowKey="codigo_queja"
        :data-source="complaints"
        :pagination="false"
        :scroll="{ x: '1220px' }"
      >
        <div
          slot="codigo_queja"
          class="title-size font-bold text-gray-500 text-center"
        >
          Id. de radicado
        </div>
        <div
          slot="nombres"
          class="title-size font-bold text-gray-500 text-center"
        >
          Responsable
        </div>
        <div
          slot="company_name"
          class="title-size font-bold text-gray-500 text-center"
        >
          Entidad
        </div>
        <div
          slot="macro_motivo_cod__name"
          class="title-size font-bold text-gray-500 text-center"
        >
          Motivo de la queja
        </div>
        <div
          slot="estado_cod__name"
          class="title-size font-bold text-gray-500 text-center"
        >
          Estado
        </div>

        <div slot="complaint_type" class="title-size font-bold text-gray-500">
          Queja principal
        </div>
        <div slot="creation_date" class="title-size font-bold text-gray-500">
          Fecha de creación
        </div>
        <div slot="actions" class="title-size font-bold text-gray-500">
          Acciones
        </div>

        <div slot="actions" slot-scope="text, record">
          <button
            class="flex justify-center col-span-4 text-xs mx-auto text-blue h-min"
            @click="complaintDetails($event, record)"
          >
            <a-icon
              type="eye"
              theme="twoTone"
              two-tone-color="#3366cc"
              :style="{
                fontSize: '20px',
              }"
            />
            <b class="my-auto ml-2">Ver</b>
          </button>
        </div>
      </a-table>
      <div class="p-4 flex justify-end">
        <a-pagination
          v-model="page"
          :page-size="pageSize"
          :page-size-options="pageSizeOptions"
          :total="count"
          show-size-changer
          size="large"
          @showSizeChange="onShowSizeChange"
        />
      </div>
    </div>
    <div v-if="loading" class="absolute w-full justify-center mt-7">
      <a-spin>
        <a-icon slot="indicator" type="loading" style="font-size: 64px" spin />
      </a-spin>
    </div>
  </div>
</template>
<script>
import ModalConfirmation from "@/components/Dashboard/Users/ModalConfirmation.vue";
import ComplaintStatusLabel from "./ComplaintStatusLabel";
import ComplaintsFilter from "./ComplaintsFilter";
import TotalComplaints from "./TotalComplaints.vue";
import capitalize from "@/utils/checkForms.js";
import formatDate from "@/utils/formatDate.js";
import moment from "moment";
import {format} from "date-fns";
import JSZip from 'jszip';

const columnText = {
  codigo_queja: "Id. de radicado",
  nombres: "Responsable",
  company_name: "Entidad",
  macro_motivo_cod__name: "Motivo de la queja",
  estado_cod__name: "Estado",
  complaint_type: "Queja principal",
  creation_date: "Fecha de creación",
  actions: "Acciones",
}

const columns = [
  {
    slots: { title: "codigo_queja" },
    dataIndex: "codigo_queja",
    key: "codigo_queja",
    width: 100,
  },
  {
    slots: { title: "nombres" },
    dataIndex: "nombres",
    key: "nombres",
    width: 100,
  },
  {
    slots: { title: "company_name" },
    dataIndex: "company_name",
    key: "company_name",
    width: 110,
  },
  {
    slots: { title: "macro_motivo_cod__name" },
    dataIndex: "macro_motivo_cod__name",
    key: "macro_motivo_cod__name",
    width: 250,
  },
  {
    slots: { title: "estado_cod__name" },
    dataIndex: "estado_cod__name",
    key: "estado_cod__name",
    width: 80,
  },
  {
    slots: { title: "complaint_type" },
    dataIndex: "complaint_type",
    key: "complaint_type",
    width: 100,
  },
  
  {
    slots: { title: "creation_date" },
    dataIndex: "creation_date",
    key: "creation_date",
    width: 100,
  },
  {
    slots: { title: "actions" },
    dataIndex: "actions",
    key: "actions",
    scopedSlots: { customRender: "actions" },
    width: 75,
  },
];

const columnsExcelBackup = columns.filter(c => c.dataIndex !== "actions").map(c => ({
  label: columnText[c.slots.title],
  field: c.dataIndex
}))

export default {
  components: {
    ModalConfirmation,
    ComplaintsFilter,
    TotalComplaints,
  },
  data() {
    return {
      // Excel
      collapsed: false,
      loadingReport: false,
      complaintsInfo: [],
      columnsExcel: columnsExcelBackup,
      json_data: [],
      json_csv: [],
      filtersToDownload: { columns: [], data: [] },
      pageSizeDownload: 1000,
      file_name: "Búsqueda de quejas (Completo)",
      progress: 0,
      // End Excel
      columns,
      form: {},
      legend: "",
      count: 0,
      complaints: [],
      selectedRowKeys: [], // Check here to configure the default column
      loading: false,
      page: 1,
      showModal: false,
      pageSize: 100,
      start_query: {},
      pageSizeOptions: ["50", "100", "150", "200", "250"],
    };
  },
  created() {
    
    if ( !this.form.start_date || this.form.start_date == "" ) {
      const start_date = moment()
        .set(new Date())
        .subtract(7, "d")
        .format("YYYY-MM-DD");
      const end_date = moment().set(new Date()).format("YYYY-MM-DD");

      this.form.start_date = start_date;
      this.form.end_date = end_date;
    }
    if (this.$route.query.page) {
      this.page = parseInt(this.$route.query.page);
    } else {
      this.page = 1;
    }
    if (this.$route.query.pageSize) {
      this.pageSize = parseInt(this.$route.query.pageSize);
    } else {
      this.pageSize = 100;
    }

    this.undoneQuery();

    this.fetchComplaints();
  },
  watch: {
    page: function (newVal, oldVal) {
      if (newVal == this.$route.query.page) {
        return;
      } else {
        this.updateRouterQuery();
      }
    },
    pageSize: function (newVal, oldVal) {
      if (newVal == this.$route.query.pageSize) {
        return;
      } else {
        this.$router.replace({
          query: { page: this.page, pageSize: this.pageSize },
        });
      }
    },
    "$route.query.page": function () {
      this.fetchComplaints();
    },
  },
  methods: {
    complaintDetails(checked, record) {
      this.$router.push({
        name: "ComplaintDetails",
        query: { complaint_id: record.codigo_queja },
      });
    },
    setQueries(myForm) {
      this.legend = "";
      this.form = myForm;
      this.showToast("info", "Buscando resultados...");
      if (1 == this.$route.query.page || !this.$route.query.page) {
        this.updateRouterQuery();
        this.fetchComplaints();
      }
      this.page = 1;
    },
    undoneQuery() {
      if (this.$route.query?.params) {
        let queryArray = this.$route.query?.params.split("&");
        queryArray.forEach((element) => {
          let param = element.split("=");
          this.form[param[0]] = param[1];
        });
        this.start_query = this.form;
      }
    },
    openNotification() {
      this.$notification.open({
        key: "Id_DescargaArchivos",
        duration: 0,
        message: 'Preparando archivo',
        description:
      <a-spin tip="Cargando">
      <div class="spin-content text-center align-center">
        <a-icon style="font-size: 20px" type="file-excel" />
        Se está generando el archivo, por favor espere.
      </div>
    </a-spin>, 
        onClick: () => {
        
          console.log('Notification Clicked!');
        },
      });
    },
    makeQuery() {
      if (this.form) {
        let query_params = Object.keys(this.form)
          .map((key) => {
            if (this.form[key] && this.form[key] !== "") {
              return key + "=" + this.form[key];
            }
          })
          .filter((data) => !!data)
          .join("&");
        return query_params;
      }
      return null;
    },
    async fetchComplaints() {
      
      // Clear data to download
      this.json_csv = [];
      this.json_data = [];
      this.collapsed = false;
      
      let query = `?page_size=${this.pageSize}&page=${this.page}`;

      let query_params = this.makeQuery();
      query += query_params ? `&${query_params}` : "";

      //Get complaints
      this.loading = true;
      let { error, data } = await this.$api.getComplaintsAll(query);
      this.loading = false;
      if (error) this.legend = "Ocurrió un error con la consulta";

      if (data) {
        //Get total complaints
        this.loading = true;
        let res_count = await this.$api.getComplaintsAll(query + `&get_count=yes`);
        this.loading = false;
        let total = 0;
        
        if (res_count.data){
          total = res_count.data.count;
        }
          
        this.count = total;
        if (this.count != 0) {
          this.legend = "";
          this.complaints = data.results.map((complaint) => {
            if (complaint.codigo_queja_principal)
              complaint.estado_duplicidad__name = complaint.codigo_queja_principal =
                "Unificada en " + complaint.codigo_queja_principal;
            else if (complaint.estado_duplicidad__name === "Duplicada")
              complaint.estado_duplicidad__name = "Duplicada";
            else complaint.estado_duplicidad__name = "Es queja principal";
            return {
              ...complaint,
              nombres: capitalize(complaint.nombres),
              company_name: capitalize(complaint.company_name),
              creation_date: formatDate(complaint.fecha_creacion),
              macro_motivo_cod__name:
                complaint.macro_motivo_cod__name[0]?.toUpperCase() +
                complaint.macro_motivo_cod__name.substring(1).toLowerCase(),
              complaint_type: (
                <ComplaintStatusLabel
                  left={true}
                  status={complaint.estado_duplicidad__name}
                />
              ),
              estado_cod__name: (
                <ComplaintStatusLabel
                  status={complaint.estado_cod__name}
                  danger="true"
                />
              ),
            };
          });
          this.complaintsInfo = data.results.map((complaint, idx) => {
            if (complaint.codigo_queja_principal)
              complaint.estado_duplicidad__name = complaint.codigo_queja_principal =
                  "Unificada en " + complaint.codigo_queja_principal;
            else if (complaint.estado_duplicidad__name === "Duplicada")
              complaint.estado_duplicidad__name = "Duplicada";
            else complaint.estado_duplicidad__name = "Es queja principal";
            return {
              ...complaint,
              idx,
              nombres: capitalize(complaint.nombres),
              company_name: capitalize(complaint.company_name),
              macro_motivo_cod__name:
                  complaint.macro_motivo_cod__name[0]?.toUpperCase() +
                  complaint.macro_motivo_cod__name.substring(1).toLowerCase(),
              complaint_type: complaint.estado_duplicidad__name,
              creation_date: formatDate(complaint.fecha_creacion),
            };
          });
        } else {
          this.legend = "No se encontraron resultados";
          this.complaints = [];
          this.complaintsInfo = []
        }
      }
    },
    updateRouterQuery() {
      this.$router
        .replace({
          query: {
            page: this.page,
            params: this.makeQuery(),
          },
        })
        .catch((error) => error);
    },
    onShowSizeChange(page, page_size) {
      this.page = page;
      this.pageSize = page_size;
      this.fetchComplaints();
    },

    async onGetAllComplaints(doc_type='xlsx') {
      this.openNotification();
      this.loading = true;

      if (doc_type=='xlsx' || doc_type=='csv'){
        
        const filename = `${this.file_name}.${doc_type}`;

        let query = `?get_file=${doc_type}`;
        let query_params = this.makeQuery();
        query += query_params ? `&${query_params}` : "";

        const promise = this.$api.getComplaintsAllFile(query);

        promise.then((response) => {
          const link = document.createElement('a')

          link.href = response.data.url
          link.setAttribute('download', filename)
          document.body.appendChild(link)

          link.click();
          this.$notification.close("Id_DescargaArchivos")
          })
          .catch((error) => {
            this.loadingReport = false;
            this.$notification.close("Id_DescargaArchivos")
            
            this.$notification.open({
              message: '¡Ups! Algo salió mal.',
              description:
                'Lo sentimos, pero parece que hay un problema. Intentalo más tarde.',
              icon: <a-icon type="frown" style="color: 'red'" />,
            });
            
          }
        );
      };

      this.loading = false;

    },

    showToast(result = "info", msg) {
      this.$notification[result]({
        message: "Resultado",
        description: msg,
        onClick: () => {},
      });
    },
    delay(n){
        return new Promise(function(resolve){
            setTimeout(resolve,n*1000);
        });
    }
  },
};
</script>

<style>
.search-complaints .ant-table-thead > tr > th {
  text-align: center;
}
.title-size {
  font-size: 1.05em;
}
</style>
