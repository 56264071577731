<template>
  <div
    class="p-5 rounded-xl shadow-xl bg-white mb-7 sm:w-1/2 md:w-72 mx-4 h-26 flex flex-col flex-nowrap text-left"
  >
    <h1 class="text-md text-gray-500 font-bold">{{ title }}</h1>
    <h1 class="text-xl">
      <span class="text-blue-700 font-bold">{{ total }}</span> {{ body }}
    </h1>
  </div>
</template>
<script>
export default {
  props: {
    total: Number,
    title: String,
    body: { type: String, default: " quejas registradas" },
  },
  data() {
    return {};
  },
};
</script>
