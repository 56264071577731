<template>
  <div class="h-min-content w-full">
    <ComplaintsTable />
  </div>
</template>

<script>
import ComplaintsTable from "@/components/Dashboard/Complaints/ComplaintsTable.vue";
export default {
  components: {
    ComplaintsTable,
  },
};
</script>

<style>
</style>